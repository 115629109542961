<template>
  <div class="flex" style="height:100%">
    <div style="flex:1;height:100%;padding-bottom:120px;overflow-y:scroll">
      <!-- 주문 방식 -->
      <div class="order position-relative"  @mouseleave="leave">
        <step-title :step="1">내 서비스에 적합한 <b>주문 방식</b>을 선택해주세요.</step-title>
        <div class="grid">
          <div v-for="(pack, p_idx) in packages"
               :key="`pack-${p_idx}`"
               :class="{'selected': isSelected(pack)}"
               class="package-box unselect"
               @mouseover="hover(pack)"

               @click="clickItem(pack)">
            <img :src="pack.icon" :class="{'icon-selected': isSelected(pack)}" style="margin-top:12px">
            <div class="margin-top-4 body4-medium">{{ packName(pack.name) }}
              <span class="body6" v-if="packSubName(pack.name)!==''"><br>{{ packSubName(pack.name) }}</span></div>
          </div>
        </div>

        <div class="box-product-wrapper" v-if="selectedItem">
          <div class="box-product">
            <div class="triangle" :style="`left:${selectedItem.index === 0 ? 84 : (selectedItem.index * 192) + 84 }px`"></div>
            <img :src="selectedItem.img" style="width:244px">
            <div style="flex: 1;margin-left:68px">
              <div class="h6 primary">{{ packName(selectedItem.name) }}<span class="body6" v-if="packSubName(selectedItem.name)!==''"><br>{{ packSubName(selectedItem.name) }}</span></div>
              <div class="sub3 price" style="text-decoration:line-through;margin-top:12px">
                <span v-if="selectedItem.price.is_discount">{{ selectedItem.price.org_price|currencyNum }}</span></div>
              <div><span class="price h5">{{ selectedItem.price.price|currencyNum }}</span>원
                <span v-if="selectedItem.price.is_subscription" class="sub3">/월</span></div>
              <div class="body4" style="color:white !important;margin-top:32px" v-html="selectedItem.info"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!-- 장바구니 -->
    <cart :service="service" @next="$emit('next')"></cart>
  </div>
</template>

<script>
  import StepTitle from "./StepTitle";
  import Cart from "./Components/Cart";
  export default {
    name: "Step1",
    components: {
      Cart,
      StepTitle
    },
    props: {
      service: {
        type: Object
      },
      alreadyPacks: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    created() {
      this.getData();
    },
    data() {
      return {
        packages: [],
        selectedItem: undefined
      }
    },
    methods: {
      getData() {
        this.$axios.get('/user/0/mapping/product?category=6&fields=id,name,params,img,price,category,info&page_length=100&ordering=name').then(res=>{
          res.data.data = res.data.data.filter(item=>{
            item.icon = item.params.filter(param => { return param.name === '아이콘' })[0].value;
            item.key = item.params.filter(param => { return param.name === 'key' })[0].value;
            item.pay_type = item.params.filter(param => { return param.name === '결제방식' })[0].value[0].name;
            item.price.org_price = item.price.price;
            item.price.price = item.price.price - item.price.discount_price;
            let packIndex = this.alreadyPacks.findIndex(pack => { return pack.key === item.key });

            if(packIndex === -1) {
              return true;
            } else if(this.alreadyPacks[packIndex].setting.payment_type !== item.pay_type && item.pay_type !== '결제없음') {
              return true;
            }
            return false;
          });

          let data = res.data.data.filter(item => {
            return item.pay_type === '결제없음' || item.key === 'delivery'
          });

          data.forEach((pack,idx) => {
            let filtered = res.data.data.filter(item => { return item.key === pack.key && item.pay_type !== pack.pay_type });
            if(filtered.length>0) {
              pack.option = filtered[0];
              pack.add_option = false;
            }
            pack.index = idx;
          });

          this.packages = data;
        });
      },
      packName(name) {
        return name.split('(')[0];
      },
      packSubName(name) {
        let names = name.split('(');
        if(names.length===1) {
          return '';
        } else {
          return '('+names[1];
        }
      },
      isSelected(pack) {
        return this.service.conversions.findIndex(item => { return item.id === pack.id }) > -1;
      },
      clickItem(item) {
        this.service.conversions = [];
        this.service.conversions.push(this.cloneItem(item));

        /*let index = this.service.conversions.findIndex(i=>{ return i.id ===  item.id });
        if(index === -1) {
          this.service.conversions.push(item);
        } else {
          this.service.conversions.remove(index);
        }*/
      },
      hover(pack) {
        this.selectedItem=pack;
        //document.getElementsByTagName('html')[0].style.overflow = 'hidden';
      },
      leave() {
        this.selectedItem = undefined;
        //document.getElementsByTagName('html')[0].style.overflow = 'auto';
      }
    },
    computed: {
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .order
    width 760px
    height 100%
    margin 0 auto
  .grid
    grid-template-columns repeat(4, 1fr)
    grid-gap 12px

  .package-box
    background-color white
    border 1px solid #eeeeee
    border-radius 6px
    padding 12px
    text-align center
    color $sub2
  .selected
    border 1px solid $primary
    color $primary

  .box-product-wrapper
    position absolute
    padding 12px 0 120px 0

  .box-product
    background-color $main
    color white
    border-radius 8px
    width 760px
    padding 40px
    display flex
    align-items flex-start
    text-align left
    position relative
  .triangle
    position absolute
    width 14px
    height 14px
    transform rotate(45deg)
    top -7px
    background-color $main

  .icon-selected
    filter invert(54%) sepia(46%) saturate(5884%) hue-rotate(360deg) brightness(100%) contrast(110%)
</style>
